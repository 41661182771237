import React from "react"
import { Link } from "gatsby"
import { BenefitsData, BenefitsConfig } from "../../config/config-data"


const Benefits = ({ GradientAnimation }) => (

  <section id={BenefitsConfig.section_header}>
    {BenefitsConfig.section_header &&
    <div id="benefit_1662791386561" className={`Heading_Text ${!GradientAnimation ? "Heading_Gradient" : ""}`}>{BenefitsConfig.section_header}</div>
    }
    {BenefitsConfig.section_sub_header &&
    <div id="benefit_1662791559596" className="Normal_Text" style={{paddingBottom:"var(--Text_Size_Five)"}}>{BenefitsConfig.section_sub_header}</div>
    }
    <div id="benefit_1662791560660" style={{justifyContent:"space-evenly"}} className="Flex_Container">
      {BenefitsData.map(({ benefits_heading, benefits_text, button_label, button_page_link, button_url_link, button_url_new_tab, benefits_image, benefits_image_height, benefits_image_width, benefits_image_stretch_width }) => (
      <div id="benefit_1662791595034" key={benefits_heading} style={{width:"50vh"}} className="Card">
        {benefits_image &&
        <div id="benefit_1662791597338">
          <img id="benefit_1662791598842" src={benefits_image} style={{borderRadius:"2vmin", width:`${benefits_image_stretch_width}`, height:"auto",}} height={benefits_image_height} width={benefits_image_width} loading="lazy" alt="Image" />
        </div>
        }
        <div id="benefit_1662791600714" className="Card_Container">
          {benefits_heading &&
          <div id="benefit_1662791602347" className="Sub_Heading_Text">{benefits_heading}</div>
          }
          {benefits_text &&
          <div id="benefit_1662791604370" className="Normal_Text">{benefits_text}</div>
          }
          <div id="benefit_1662791605698" className="Extra_Spacing" />
          {button_label && button_url_link &&
          <a href={button_url_link} title="Click Here" target={button_url_new_tab && "_blank"} rel="noopener noreferrer"><button>{button_label}</button></a>
          }
          {button_label && button_page_link &&
          <Link exact="true" to={button_page_link}><button>{button_label}</button></Link>
          }
        </div>
      </div>
      ))}
    </div>
  </section>
);

export default Benefits